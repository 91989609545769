var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"float-center mt-2 mb-2 table-title"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.duocsapmatter", true, "Asignaturas creadas en SAP" ).toUpperCase())+" ")])]),(
      (_vm.user_position &&
        [1, 2].includes(_vm.user_position.position) &&
        [1, 2].includes(_vm.user.groups[0])) ||
      _vm.user.is_superuser
    )?_c('div',{staticClass:"d-flex"},[(_vm.allows_crud)?_c('b-button',{staticClass:"ml-0 mr-auto",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`upload-excel-matter-sap-modal`)}}},[_vm._v(" Actualizar Excel "+_vm._s(_vm.$getVisibleNames( "teaching.duocsapmatter", true, "Asignaturas creadas en SAP" ))+" ")]):_vm._e()],1):_vm._e(),_c('div',[_c('NewGenericBTable',{key:_vm.key_duoc_sap_matter_list,attrs:{"fields":_vm.fields,"pre_current_page":_vm.current_page,"pre_multiple_search":_vm.current_tag,"url":'/integrations/duoc-sap-matter/'},on:{"current_page":_vm.currentPage,"current_tag":_vm.currentTag},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            'Eliminar la ' +
            _vm.$getVisibleNames(
              'teaching.duocsapmatter',
              false,
              'Asignatura creada en SAP'
            )
          ),expression:"\n            'Eliminar la ' +\n            $getVisibleNames(\n              'teaching.duocsapmatter',\n              false,\n              'Asignatura creada en SAP'\n            )\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.deletedDuocSapMatter(row.item.id)}}})]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`upload-excel-matter-sap-modal`,"title":`Actualizar ${_vm.$getVisibleNames(
      'teaching.duocsapmatter',
      true,
      'Asignaturas creadas en SAP'
    )}`,"size":"md","hide-footer":""}},[_c('FileSelector',{attrs:{"post_file":false,"restrict_file_weight":false},on:{"file":_vm.uploadFile}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }