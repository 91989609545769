<template>
  <div>
    <div class="float-center mt-2 mb-2 table-title">
      <strong>
        {{
          $getVisibleNames(
            "teaching.duocsapmatter",
            true,
            "Asignaturas creadas en SAP"
          ).toUpperCase()
        }}
      </strong>
    </div>
    <div
      class="d-flex"
      v-if="
        (user_position &&
          [1, 2].includes(user_position.position) &&
          [1, 2].includes(user.groups[0])) ||
        user.is_superuser
      "
    >
      <b-button
        v-if="allows_crud"
        class="ml-0 mr-auto"
        size="sm"
        @click="$bvModal.show(`upload-excel-matter-sap-modal`)"
      >
        Actualizar Excel
        {{
          $getVisibleNames(
            "teaching.duocsapmatter",
            true,
            "Asignaturas creadas en SAP"
          )
        }}
      </b-button>
    </div>
    <div>
      <NewGenericBTable
        :key="key_duoc_sap_matter_list"
        :fields="fields"
        :pre_current_page="current_page"
        :pre_multiple_search="current_tag"
        :url="'/integrations/duoc-sap-matter/'"
        @current_page="currentPage"
        @current_tag="currentTag"
      >
        <template v-if="allows_crud" #cell(actions)="row">
          <button-delete
            @click="deletedDuocSapMatter(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              'Eliminar la ' +
              $getVisibleNames(
                'teaching.duocsapmatter',
                false,
                'Asignatura creada en SAP'
              )
            "
          ></button-delete>
        </template>
      </NewGenericBTable>
    </div>
    <b-modal
      :id="`upload-excel-matter-sap-modal`"
      :title="`Actualizar ${$getVisibleNames(
        'teaching.duocsapmatter',
        true,
        'Asignaturas creadas en SAP'
      )}`"
      size="md"
      hide-footer
    >
      <FileSelector
        :post_file="false"
        :restrict_file_weight="false"
        @file="uploadFile"
      ></FileSelector>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "DuocSapMatterList",
  components: {
    NewGenericBTable: () => import("@/components/reusable/NewGenericBTable"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key_duoc_sap_matter_list: 0,
      current_page: 1,
      current_tag: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
    }),
    fields() {
      return [
        {
          key: "begda",
          label: "begda",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "endda",
          label: "endda",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "otjid",
          label: "otjid",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "aedtm",
          label: "aedtm",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "uname",
          label: "uname",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "sigla",
          label: "sigla",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "nombre",
          label: "nombre",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "mc_seark",
          label: "mc_seark",
          //   sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
      ];
    },
  },
  methods: {
    currentPage(current_page) {
      this.current_page = current_page;
    },
    currentTag(current_tag) {
      this.current_tag = current_tag;
    },
    deletedDuocSapMatter(id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.duocsapmatter",
          true,
          "Asignaturas creadas en SAP"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/integrations/duoc-sap-matter/${id}/`)
            .then(() => {
              this.key_duoc_sap_matter_list++;
            });
        }
      });
    },
    uploadFile(file) {
      this.$swal.fire({
        title: "Subiendo...",
        text: "Por favor, espera mientras se sube el archivo.",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$restful
        .Post(`/integrations/update_duoc_sap_matters/`, file)
        .then(() => {
          this.$swal.close();
          this.$swal.fire("Éxito", "¡Archivo subido exitosamente!", "success");
          this.key_duoc_sap_matter_list++;
        })
        .catch((error) => {
          this.$swal.close();
          this.$swal.fire(
            "Error",
            "¡Hubo un problema al subir el archivo!",
            "error"
          );
          if (error.response && error.response.status === 404) {
            this.$swal.showValidationMessage(`Request failed: ${error}`);
          } else if (
            error.response &&
            error.response.status !== 404 &&
            error.response.data &&
            error.response.data.error
          ) {
            this.$swal.showValidationMessage(`${error.response.data.error}`);
          }
        });
      this.$bvModal.hide(`upload-excel-matter-sap-modal`);
    },
  },
  created() {},
};
</script>

<style scoped>
</style>

